// BLOCKDAG
// export const SKY_ROCKET_CHAIN_ID = 24171;
// export const SKY_ROCKET_CHAIN_NAME = "BlockDAG Testnet";
// export const SKY_ROCKET_CHAIN_NETWORK_CURRENCY = {name: 'BDAG', decimals: 18, symbol: 'BDAG'}
// export const SKY_ROCKET_CHAIN_RPC_URLS = ['https://rpc-testnet.bdagscan.com'];
// export const SKY_ROCKET_CHAIN_BLOCK_EXPLORER = ['https://bdagscan.com'];
// export const SKY_ROCKET_CURRENCY_NAME = 'BDAG';
// export const SKY_ROCKET_NFT_PRICE_IN_ETH = 0.1;

// export const SKY_ROCKET_NFT_FACTORY_ADDRESS = '0xCa688E21673CE9C20931c03dD7AFD5d1A9859D59';
// export const SKY_ROCKET_STORE_ADDRESS = '0x98d50B7Ebf322cFfFE86Be471c1FA46E4D23159F';
// export const SKY_ROCKET_BATTLE_ADDRESS = '0xE2c5615A69cc32911E23B2C2063d3278C81e1d2e';
//
// export const PRICE_PER_NFT = "100000000000000000"; // 0.1 ETH
//

// SONIC LABS
export const SKY_ROCKET_CHAIN_ID = 146;
export const SKY_ROCKET_CHAIN_NAME = "Sonic Network";
export const SKY_ROCKET_CHAIN_NETWORK_CURRENCY = {name: 'Sonic', decimals: 18, symbol: 'S'}
export const SKY_ROCKET_CHAIN_RPC_URLS = ['https://rpc.soniclabs.com'];
export const SKY_ROCKET_CHAIN_BLOCK_EXPLORER = ['https://SonicScan.org'];
export const SKY_ROCKET_CURRENCY_NAME = 'Sonic';
export const SKY_ROCKET_NFT_PRICE_IN_ETH = 0.001337;

export const SKY_ROCKET_NFT_FACTORY_ADDRESS = '0x08bEE8a6884946f63a7b427aC42555C8CE4918f3';
export const SKY_ROCKET_STORE_ADDRESS = '0x24a183E82B56bc4fb4cDb111E58a489A6fab054f';
export const SKY_ROCKET_BATTLE_ADDRESS = '0x46E74Db44037254E97d1198D2A96C59b333ffbda';

// export const PRICE_PER_NFT = "5000000000000000000"; // 5 ETH
export const PRICE_PER_NFT = "133700000000000"; // 0.001337 ETH
